<template>
  <div>
    <h1>Checking Authentication ...</h1>
  </div>
</template>

<script>

export default {
  data() {
    return {
      ho: "hum"
    }
  },
  mounted() {
    // Initial 
    while (this.$store.getters.status == 'loading') {
      console.log('waiting for status to change from loading')
      setTimeout(() => { console.log('waiting for auth ...') }, 1000)
    }
    if (this.$store.getters.signed_in) {
      console.log('auth checked')
      console.log('visit path is ' + this.$store.getters.visit_path)
      if (this.$store.getters.visit_path) {
        this.$router.push(this.$store.getters.visit_path)
      } else {
        this.$router.push('/')
      }
    }
    else {
      console.log('auth failed')
      this.$router.push('/signin')
    }
  }
}
</script>
