<template>
  <b-container>
    <b-row align-h="center">
      <b-col cols="12">
        <b-card title="Sign In" class="text-left">
          <b-form>
            <b-form-group label="Username" label-form="username">
              <b-form-input id="username" v-model="username"></b-form-input>
            </b-form-group>
            <b-form-group label="Passcode" label-form="passcode">
              <b-form-input
                id="passcode"
                v-model="passcode"
                type="password"
              ></b-form-input>
            </b-form-group>
            <b-alert variant="danger" :show="signin_error">Sign In Failed</b-alert>
            <b-button @click="signin" type="submit" variant="primary"
              >Sign In</b-button
            >
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      username: "",
      passcode: ""
    }
  },
  methods: {
    signin: function() {
      let username = this.username
      let passcode = this.passcode
      this.$store
        .dispatch("signin", { username, passcode })
        .then(() => this.$router.push("/"))
        .catch((err) => {
          console.log(this.$store.status)
          console.log(err)
        })
    }
  },
  computed: {
    signin_error() {
      return this.$store.state.status == "error"
    }
  }
}
</script>
