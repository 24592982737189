<template>
  <div class="annuareports">
    <b-container fluid>
      <b-card-group columns>
        <b-card title="Menu">
          <ul class="menu">
            <li class="mb-3">
              <b-card-text>
                <b-form-group label="Annual Report Year">
                <b-form-select v-model="report_year" :options="report_years" @change="onReportYearSelect">
                </b-form-select>
                </b-form-group>

                <b-link to="annual-report-list">
                  <strong>
                    Annual Report Review
                  </strong>
                </b-link>
              </b-card-text>
            </li>
            <li>
              <b-button variant="primary" @click="reloadData">
                {{
                  table_stats_busy || table_pws_missing_busy
                    ? "Loading ..."
                    : "Reload Data"
                }}
              </b-button>
            </li>
          </ul>
        </b-card>
        <b-card title="Report Stats">
          <b-table :busy="table_stats_busy" hover stacked :items="table_items">
          </b-table>
          <h5>Review Status</h5>
          <b-table
            :busy="table_stats_busy"
            hover
            stacked
            :items="table_review_items"
          >
          </b-table>
        </b-card>
        <b-card title="Reports Submitted">
          <bar-chart
            :chartData="stats"
            :options="this.chartoptions"
          ></bar-chart>
        </b-card>
        <b-card title="Report Reviews">
          <pie-chart
            :chartData="chart_review_stats"
            :options="this.chartoptions"
          ></pie-chart>
        </b-card>
        <b-card :title="'PWS Missing: ' + pws_missing_items.length">
          <b-card-text>
            <b-link :href="missing_csv_url">Download CSV File</b-link>
          </b-card-text>
          <b-table
            :busy="table_pws_missing_busy"
            sticky-header
            hover
            :items="pws_missing_items"
          >
          </b-table>
        </b-card>
        <b-card :title="'Followups: ' + pws_followups_items.length">
          <b-card-text>
            <b-link :href="followups_csv_url">Download CSV File</b-link>
          </b-card-text>
          <b-table
            :fields="pws_followups_fields"
            :busy="table_pws_followups_busy"
            sticky-header
            hover
            :items="pws_followups_items"
          >
          </b-table>
        </b-card>
        <b-card :title="'Population Changes: ' + pws_pop_changes_items.length">
          <b-card-text>
            <b-link :href="pop_changes_csv_url">Download CSV File</b-link>
          </b-card-text>
          <b-table
            :busy="table_pws_pop_changes_busy"
            :fields="pws_pop_changes_fields"
            sticky-header
            hover
            :items="pws_pop_changes_items"
          >
          </b-table>
        </b-card>
        <b-card :title="'LRO Changes: ' + pws_lro_changes_items.length">
          <b-card-text>
            <b-link :href="lro_changes_csv_url">Download CSV File</b-link>
          </b-card-text>
          <b-table
            :busy="table_pws_lro_changes_busy"
            :fields="pws_lro_changes_fields"
            sticky-header
            hover
            :items="pws_lro_changes_items"
          >
          </b-table>
        </b-card>
      </b-card-group>
    </b-container>
  </div>
</template>

<script>
import BarChart from "../components/BarChart.js"
import PieChart from "../components/PieChart.js"

export default {
  name: "AnnualReportStats",
  components: {
    BarChart,
    PieChart
  },
  data() {
    return {
      report_years: [],
      report_year: new Date().getFullYear(),
      //missing_csv_url:
      //  process.env.VUE_APP_API_URI + "/annual-reports-missing?filetype=csv",
      //followups_csv_url:
      //  process.env.VUE_APP_API_URI + "/annual-report-followups?filetype=csv",
      //pop_changes_csv_url:
      //  process.env.VUE_APP_API_URI + "/annual-report-pop-changes?filetype=csv",
      //lro_changes_csv_url:
      //  process.env.VUE_APP_API_URI + "/annual-report-lro-changes?filetype=csv",
      stats: {},
      chart_review_stats: {},
      chartoptions: {
        scales: {
          yAxes: [{ ticks: { min: 0 } }]
        }
      },
      stats_ar_count: 0,
      stats_pws_count: 0,
      table_items: [{ pws_count: 0, report_count: 0 }],
      table_review_items: [],
      pws_lro_changes_busy: false,
      pws_lro_changes_items: [],
      pws_lro_changes_fields: [
        "pws_id",
        "ar_last_name",
        "ar_first_name",
        "ar_title",
        "ss_last_name",
        "ss_first_name",
        "ss_title"
      ],
      pws_pop_changes_busy: false,
      pws_pop_changes_items: [],
      pws_pop_changes_fields: [
        "pws_id",
        "ss_pop",
        "ar_pop",
        "old_tcr_sample_count",
        "new_tcr_sample_count"
      ],
      pws_followups_items: [],
      pws_followups_fields: ["pws_id", "pws_name", "ac_name", "ac_phone"],
      pws_missing_items: [],
      table_stats_busy: false,
      table_pws_missing_busy: false,
      table_pws_followups_busy: false,
      table_pws_pop_changes_busy: false,
      table_pws_lro_changes_busy: false,
      loading: false
    }
  },
  computed: {
      missing_csv_url() {
        return process.env.VUE_APP_API_URI + "/annual-reports-missing?filetype=csv&report_year=" + this.report_year
      },
      followups_csv_url() {
        return process.env.VUE_APP_API_URI + "/annual-report-followups?filetype=csv&report_year=" + this.report_year
      },
      pop_changes_csv_url() {
        return process.env.VUE_APP_API_URI + "/annual-report-pop-changes?filetype=csv&report_year=" + this.report_year
      },
      lro_changes_csv_url() {
        return process.env.VUE_APP_API_URI + "/annual-report-lro-changes?filetype=csv&report_year=" + this.report_year
      }
  },
  created() {
    // add years to select
    const ymax = new Date().getFullYear() + 1
    const ymin = 2021
    let y = ymin
    while (y <= ymax) {
      this.report_years.push(y)
      y = y + 1
    }

    // figure out the report year
    const ls_report_year = localStorage.getItem('ar_report_year')
    if (ls_report_year) {
      console.debug('got saved ar year', ls_report_year)
      this.report_year = parseInt(ls_report_year)
    }
  },
  mounted() {
    this.fetchReportStats()
    this.loadChartStats()
    this.loadChartReviewStats()
    this.fetchPWSMissing()
    this.fetchAnnualReportFollowups()
    this.fetchAnnualReportPopChanges()
    this.fetchAnnualReportLROChanges()
  },
  methods: {
    onReportYearSelect() {
      console.debug('selected report year', this.report_year)
      localStorage.setItem('ar_report_year', this.report_year)
    },
    loadChartStats() {
      this.stats = {
        labels: [""],
        datasets: [
          {
            label: "Reports Submitted",
            backgroundColor: "#28a745",
            data: [this.stats_ar_count]
          },
          {
            label: "Total Active PWS",
            backgroundColor: "#6f42c1",
            data: [this.stats_pws_count]
          }
        ],
        options: {
          scales: {
            yAxes: [{ ticks: { min: 0 } }]
          }
        }
      }
    },
    loadChartReviewStats() {
      let labels = []
      let data = []
      for (var key in this.table_review_items[0]) {
        labels.push(key)
        data.push(this.table_review_items[0][key])
      }
      this.chart_review_stats = {
        labels: labels,
        datasets: [
          {
            label: "Reports Submitted",
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)"
            ],
            data: data
          }
        ]
      }
    },
    fetchReportStats() {
      const path = process.env.VUE_APP_API_URI + "/annual-report-stats"
      this.table_stats_busy = true
      this.axios
        .get(path, { params: { report_year: this.report_year } })
        .then((res) => {
          if (res.data) {
            // save dataset
            this.stats_ar_count = res.data.ar_count
            this.stats_pws_count = res.data.pws_count
          }
          // fill chart
          this.loadChartStats()
          this.table_items = [
            { pws_count: res.data.pws_count, report_count: res.data.ar_count }
          ]
          this.table_review_items = [res.data.ar_status_counts]
          this.loadChartReviewStats()
          this.table_stats_busy = false
        })
        .catch((error) => {
          this.table_stats_busy = false
          console.error(error)
        })
    },
    fetchPWSMissing() {
      const path = process.env.VUE_APP_API_URI + "/annual-reports-missing"
      this.table_pws_missing_busy = true
      this.axios
        .get(path, { params: { report_year: this.report_year } })
        .then((res) => {
          if (res.data) {
            // save dataset
            this.pws_missing_items = res.data
          }
          this.table_pws_missing_busy = false
        })
        .catch((error) => {
          this.table_pws_missing_busy = false
          console.error(error)
        })
    },
    fetchAnnualReportFollowups() {
      const path = process.env.VUE_APP_API_URI + "/annual-report-followups"
      this.table_pws_followups_busy = true
      this.axios
        .get(path, { params: { report_year: this.report_year } })
        .then((res) => {
          if (res.data) {
            // save dataset
            this.pws_followups_items = res.data
          }
          this.table_pws_followups_busy = false
        })
        .catch((error) => {
          this.table_pws_followups_busy = false
          console.error(error)
        })
    },
    fetchAnnualReportPopChanges() {
      const path = process.env.VUE_APP_API_URI + "/annual-report-pop-changes"
      this.table_pws_pop_changes_busy = true
      this.axios
        .get(path, { params: { report_year: this.report_year } })
        .then((res) => {
          this.table_pws_pop_changes_busy = false
          if (res.data) {
            // save dataset
            this.pws_pop_changes_items = res.data
          }
        })
        .catch((error) => {
          this.table_pws_pop_changes_busy = false
          console.error(error)
        })
    },
    fetchAnnualReportLROChanges() {
      const path = process.env.VUE_APP_API_URI + "/annual-report-lro-changes"
      this.table_pws_lro_changes_busy = true
      this.axios
        .get(path, { params: { report_year: this.report_year } })
        .then((res) => {
          this.table_pws_lro_changes_busy = false
          if (res.data) {
            // save dataset
            this.pws_lro_changes_items = res.data
          }
        })
        .catch((error) => {
          this.table_pws_lro_changes_busy = false
          console.error(error)
        })
    },

    reloadData() {
      this.loadChartStats()
      this.fetchPWSMissing()
      this.fetchAnnualReportFollowups()
      this.fetchAnnualReportPopChanges()
      this.fetchReportStats()
      this.fetchReportStats()
      this.fetchAnnualReportLROChanges()
    }
  }
}
</script>
<style>
ul.menu {
  list-style-type: none;
  padding: 0;
}
</style>
